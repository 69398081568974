import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  formatCurrency,
  formatAmount2,
  formatAmountWithDecimals,
  formatTotalOddsValue,
  forceRoundIfAny,
} from '../../../../../modules/bets/utils/formatters';
import { formatMessage, twoDecimalsFloat } from '../../../../../modules/bets/utils';
import {
  betsSlipClear,
  betsSlipClearCreateStatus,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betsSlipToggleAutoAcceptOddChange,
  betsSlipToggleAllowTicketPlaceWithInvalidEvents,
  betsSlipSetTicketOnline,
  betsBonusRequestEvaluation,
  betsSlipToggleWinnerFunBoost,
  betSlipSetFreeBetIndex,
  betSlipFreeBetClear,
  betsSlipSetMultiBetDayNumber,
} from '../../../../../modules/bets/store/actions/betsSlip';

import { ticketWinnerFunOpenedListRequest } from '../../../../../modules/bets/store/actions/tickets';
import { prematchCreateTicketRequest } from '../../../../../modules/bets/store/actions/prematch';
import { liveCreateTicketRequest } from '../../../../../modules/bets/store/actions/live';
import { getBetsState } from '../../../../../modules/bets/store/selectors/betData';

import { doLogin } from '../../../../../modules/casino/store/actions/authentication';

import { getBetsBonuses, getBetsRingFences } from '../../../../../modules/bets/store/selectors/wallet';

import { getWinnerFunBoostNum } from '../../../../../modules/winner-fun/store/selectors/winnerFunData';
import { RootState } from '@/store';

import styled from 'styled-components';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { limits as betLimits } from '../../../../../modules/bets/store/reducers/betsSlip';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

//import FixHorizontalScroll from './fix-horizontal-scroll';

const SoldCard = (props: any) => {
  const {
    stake,
    amount,
    tax,
    taxPercent,
    totalOdds,

    minWinAmount,
    winAmount,

    totalMinWinAmount,
    totalTaxedMinWinAmount,
    totalMinWinTax,

    totalMaxWinAmount,
    totalTaxedMaxWinAmount,
    totalMaxWinTax,

    autoAcceptOddChange,
    stakeInc,
    stakeDec,
    stakeSet,
    toggleAutoAcceptOddChange,
    placeTicketEnabled,
    placeTicketErrorMessage,
    liveCreateTicket,
    prematchCreateTicket,
    clearBets,
    clearCreateStatus,
    auth,
    ticketOnline,
    betsSlipSetTicketOnline,
    ticketType,
    wallet,
    profile,
    exitFull,
    liveBets,
    prematchBets,
    bonus,
    handleDeleteButton,
    walletBonusEvaluate,
    walletBonuses,
    walletRingFences,
    requestBonusEvaluation,
    currentTicket,
    isWinnerFun,
    winnerFunEvaluate,
    winnerFunBoostNum,
    toggleWinnerFunBoost,
    winnerFunBoostActive,
    ticketWinnerFunOpenedListRequest,
    allowTicketPlaceWithInvalidEvents,
    toggleAllowTicketPlaceWithInvalidEvents,

    freeBets,
    selectedFreeBet,
    selectedFreeBetSubIndex,
    betSlipFreeBetClear,
    betSlipSetFreeBetIndex,
    freeBetsEvaluate,
    dayMultiBetNumber,
    dayMultiBets,

    t,
  } = props;

  //const isDesktop1024 = useMediaQuery('(min-width:1024px)');
  const imageType = 'desktop'; // isDesktop1024 ? 'desktop' : 'mobile';

  const [stakeAmount, setStakeAmount] = React.useState(amount);
  const [maxBet, setMaxBet] = React.useState({
    enabled: false,
    oldStake: amount,
  });
  const [ruleDialog, setRuleDialog] = React.useState({
    open: false,
    eventName: '',
    type: '',
  });

  //console.log("sold card props", props);
  const [disableSwitch, setDisableSwitch] = React.useState(false);
  const classes = {};

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const toggleDeleteDialog = () => {
    setOpenDeleteDialog((v) => !v);
  };

  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const handlePlaceBetClose = (type = 0) => {
    setOpenPlaceBet(false);
    if (typeof type !== 'object') {
      if (type === 1) {
        //window.location.hash = '#tickets-details';
      }
      if (type !== 2) {
        clearBets();
      } else {
        clearCreateStatus();
      }
    }
  };

  React.useEffect(() => {
    if (freeBetsEvaluate && freeBetsEvaluate.data && freeBets && selectedFreeBet !== -1) {
      try {
        if (freeBets[selectedFreeBet] && freeBets[selectedFreeBet].code) {
          const code = freeBets[selectedFreeBet].code;
          if (typeof freeBetsEvaluate.data[code] !== 'undefined' && !freeBetsEvaluate.data[code]) {
            betSlipFreeBetClear();
          }
        }
      } catch (err) {
        /*noop*/
      }
    }
  }, [freeBetsEvaluate, freeBets, selectedFreeBet, betSlipFreeBetClear]);

  React.useEffect(() => {
    if (liveBets && liveBets.length) {
      setDisableSwitch(true);
      if (!ticketOnline) {
        betsSlipSetTicketOnline(true);
      }
    } else {
      setDisableSwitch(false);
    }
  }, [ticketOnline, liveBets, betsSlipSetTicketOnline]);

  React.useEffect(() => {
    if (auth && ['user', 'token'].indexOf(auth.auth_type) === -1) {
      if (!isWinnerFun) {
        betsSlipSetTicketOnline(false);
      } else {
        betsSlipSetTicketOnline(true);
      }
    } else {
      betsSlipSetTicketOnline(true);
    }
  }, [auth, isWinnerFun, betsSlipSetTicketOnline]);

  React.useEffect(() => {
    let newAmount = amount;
    newAmount = forceRoundIfAny(newAmount);
    setStakeAmount(newAmount);
  }, [isWinnerFun, amount]);

  React.useEffect(() => {
    //console.log("wallet update", wallet);
    requestBonusEvaluation();
  }, [wallet, profile, requestBonusEvaluation]);

  React.useEffect(() => {
    //debug('maxBet', maxBet);

    if (maxBet.enabled) {
      let eligible = false;

      if (isWinnerFun) {
        if (winnerFunEvaluate && winnerFunEvaluate.success && winnerFunEvaluate.data) {
          eligible = winnerFunEvaluate.data.eligible;
        }
      } else {
        if (walletBonusEvaluate && walletBonusEvaluate.success && walletBonusEvaluate.data) {
          eligible = walletBonusEvaluate.data.eligible;
        }
      }

      const maxWin = betLimits.maxPayout;

      let to = totalOdds;

      if (!isWinnerFun && bonus && bonus.digitainAppliedBonus && bonus.digitainAppliedBonus.success) {
        const db = bonus.digitainAppliedBonus.bonuses.find((b: any) => b.type === 'Express');
        //debug('appli digitian bonus', db);
        if (db) {
          to = to * (1 + db.percentage / 100);
        }
      }

      //debug('totalOdds', totalOdds, 'to', to);

      let stake = maxWin / to;

      stake = Math.floor(stake * 100) / 100;

      if (stake * to > maxWin) {
        stake -= 0.01;
        stake = Math.floor(stake * 100) / 100;
      }

      if (eligible) {
        if (stake > wallet.total) {
          stake = wallet.total;
        }
      } else {
        if (isWinnerFun) {
          if (stake > wallet.total) {
            stake = wallet.total;
          }
        } else if (stake > wallet.main) {
          stake = wallet.main;
        }
      }

      if (stake > betLimits.maxAmount) {
        // max bet
        stake = betLimits.maxAmount;
      }

      stake = forceRoundIfAny(stake);
      setStakeAmount(stake);
      stakeSet(stake);
    }
  }, [maxBet, totalOdds, wallet, walletBonusEvaluate]);

  const handleAutoAcceptChange = () => {
    toggleAutoAcceptOddChange();
  };

  const handleAllowTicketPlaceWithInvalidEvents = () => {
    toggleAllowTicketPlaceWithInvalidEvents();
  };

  //const [ticketOnline, setTicketOnline] = React.useState(true);
  const handleTicketLocation = (online: any) => {
    //console.log(online ? "online" : "agentie");
    //setTicketOnline(online);
    betsSlipSetTicketOnline(online);
  };

  //const inputProps = { "aria-label": "secondary checkbox" };

  const onStakeInc = (s: any, e: any) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    if (isWinnerFun && amount === wallet.total) return;
    betSlipFreeBetClear();
    stakeInc(s);
  };

  const onStakeDec = (s: any, e: any) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    betSlipFreeBetClear();
    stakeDec(s);
  };

  const handleStakeChange = (e: any) => {
    //debug('stake change', e.target.value);

    if (selectedFreeBet !== -1) return;
    if (liveBets.length + prematchBets.length === 0) return;

    let ev = e.target.value;

    // let them input an empty string
    if (ev === '') {
      setStakeAmount('');
      stakeSet('');
      return;
    }

    // don't let them put an amount < 1
    if (ev === '0') {
      return;
    }

    // convert , to .
    ev = ev.split(',').join('.');

    // only one .
    const sev = ev.split('.');
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      ev = sev[0] + '.' + sev[1].substring(0, 2);
    }

    let v: string | number = '';

    if (ev !== '') {
      // amounts ending with . have the same value
      if (ev.endsWith('.') || ev.endsWith('.0')) {
        setStakeAmount(ev);
        return;
      }

      // only valid numbers
      if (isNaN(+ev)) {
        return;
      }

      // convert amount
      v = forceRoundIfAny(ev);
      if (isNaN(v)) return;
    }

    if (isWinnerFun && v > wallet.total) {
      v = forceRoundIfAny(wallet.total);
    }

    // update amount

    setStakeAmount(v);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const toggleMaxBet = () => {
    betSlipFreeBetClear();
    setMaxBet((mbe) => {
      if (mbe.enabled) {
        setStakeAmount(mbe.oldStake);
        stakeSet(mbe.oldStake);

        return {
          enabled: false,
          oldStake: 0,
        };
      }

      return {
        enabled: true,
        oldStake: amount,
      };
    });
  };

  let bgCls = '';
  if (winAmount > 100) {
    bgCls = 'large';
  } else if (winAmount > 50) {
    bgCls = 'medium';
  }

  const createTicket = () => {
    setOpenPlaceBet(true);

    let free_bet_code = null;
    let free_bet_name = null;
    let free_bet_pid = null;
    let free_bet_redeemable = 0;
    if (selectedFreeBet > -1) {
      const freeBet = freeBets[selectedFreeBet];
      free_bet_code = freeBet.code;
      free_bet_name = freeBet.name;
      free_bet_pid = freeBet.fbid;
      free_bet_redeemable = freeBet.redeemable;
    }

    liveCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    // prematchCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    betSlipFreeBetClear();
  };

  const handleLogin = () => {
    if (typeof exitFull === 'function') exitFull();
    localStorage.setItem('redirect_after_login', props.location.pathname);

    props.doLogin();
  };

  let loggedIn = false;
  if (auth && ['user', 'token'].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const onFreeBet = (index: number) => (subIndex: number) => () => {
    if (selectedFreeBet === index && selectedFreeBetSubIndex === subIndex) {
      betSlipFreeBetClear();
      requestBonusEvaluation();
    } else {
      betSlipSetFreeBetIndex(index, freeBets[index], subIndex);
      const freeBetAmount = freeBets[index].amount_small === 1 ? freeBets[index].amount / 100 : freeBets[index].amount;
      stakeSet(freeBetAmount);
    }
  };

  const onFreeBetIndexes = (e: any) => {
    let index = -1;
    let subIndex = -1;
    let fbid = null;

    if (e.target.dataset.index) {
      index = Number(e.target.dataset.index);
    }
    if (e.target.dataset.subindex) {
      subIndex = Number(e.target.dataset.subindex);
    }
    if (e.target.dataset.id) {
      fbid = e.target.dataset.id;
    }

    if (index === -1 || subIndex === -1) {
      return;
    }

    if (e.target.dataset.disabled && e.target.dataset.disabled === 'true') {
      if (fbid) {
        handleRuleOpen('freeBet', fbid);
      }
      return;
    }

    if (selectedFreeBet === index && selectedFreeBetSubIndex === subIndex) {
      betSlipFreeBetClear();
      requestBonusEvaluation();
    } else {
      betSlipSetFreeBetIndex(index, freeBets[index], subIndex);
      const freeBetAmount = freeBets[index].amount_small === 1 ? freeBets[index].amount / 100 : freeBets[index].amount;
      stakeSet(freeBetAmount);
    }
  };

  const buildFreeBets = () => {
    const buttons: any = [];

    if (!ticketOnline) return buttons;
    const now = Math.floor(+new Date() / 1000);

    if (isWinnerFun) return buttons;

    const freeBetAllDisable = maxBet.enabled ? true : false;

    freeBets.forEach((fb: any, i: number) => {
      if (
        (fb.product === 'sport' || fb.product === 'sport.live' || fb.product === 'sport.prematch') &&
        fb.count &&
        fb.expires > now
      ) {
        const amount = fb.amount_small === 1 ? fb.amount / 100 : fb.amount;

        let freeBetDisabled = false;
        if (
          freeBetsEvaluate &&
          freeBetsEvaluate.data &&
          typeof freeBetsEvaluate.data[fb.code] !== 'undefined' &&
          !freeBetsEvaluate.data[fb.code]
        ) {
          freeBetDisabled = true;
        }

        if (fb.product !== 'sport') {
          if (liveBets && liveBets.length && fb.product !== 'sport.live') {
            freeBetDisabled = true;
          }
          if (prematchBets && prematchBets.length && fb.product !== 'sport.prematch') {
            freeBetDisabled = true;
          }
        }

        [...Array(fb.count).keys()].forEach((j) => {
          buttons.push({
            fbIndex: i,
            fbSubIndex: j,
            name: fb.name,
            code: fb.code,
            fbid: fb.fbid,
            amount: amount,
            disabled: freeBetDisabled,
            disabledAll: freeBetAllDisable,
            selected: selectedFreeBet === i && selectedFreeBetSubIndex === j,
            clickHandler: onFreeBetIndexes,
          });
        });
      }
    });

    return buttons;
  };

  //debug('BONUS', bonus, 'AMOUNT', amount);

  let ticketAppliedBonus = null;
  if (!isWinnerFun && bonus && bonus.ticketAppliedBonus) {
    ticketAppliedBonus = {
      percentage: bonus.ticketAppliedBonus.maxPercentage,
      amountMax: bonus.ticketAppliedBonus.amountMax,
      currency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
      bonus: bonus,
    };
  }

  if (!isWinnerFun && bonus && bonus.digitainAppliedBonus && bonus.digitainAppliedBonus.success) {
    const db = bonus.digitainAppliedBonus.bonuses.find((b: any) => b.type === 'Express');
    if (db) {
      ticketAppliedBonus = {
        percentage: db.percentage,
        amountMax: db.maxAmount,
        currency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
        bonus: db,
      };
    }
  }

  let ticketBonusEligibles = null;
  if (!isWinnerFun && bonus && bonus.ticketBonusEligibles && bonus.ticketBonusEligibles.length) {
    const bonuses: any[] = [];
    bonus.ticketBonusEligibles.forEach((b: any) => {
      if (b.eligible) {
        bonuses.push(b.bonus.name);
      }
    });
    ticketBonusEligibles = {
      bonuses: bonuses.join(' + '),
    };
  }

  const scrollToBonusError = () => {
    const wrapper = document.querySelector('.bets-scroller');
    const elem = document.querySelector('.scroll-to-this-bet');
    if (wrapper && elem) {
      // @ts-ignore
      const elemTop = elem.getBoundingClientRect().top - elem.offsetParent.getBoundingClientRect().top;
      wrapper.scrollTop = elemTop;
    }
  };

  let bonusEvaluateMessagesEligible = null;
  let hasBonusEvaluateMessages = false;
  const stakeErrorBonuses: any[] = [];
  let winnerFunErrors: any[] = [];

  let free_money_used = 0;
  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  const hasBets = liveBets.length + prematchBets.length > 0;

  if (isWinnerFun) {
    bLimit = wallet.total;
    if (winnerFunEvaluate && winnerFunEvaluate.success && winnerFunEvaluate.data) {
      if (winnerFunEvaluate.data.valid) {
        valid = bLimit >= amount;
      } else {
        valid = winnerFunEvaluate.data.valid;

        if (winnerFunEvaluate.data.details && winnerFunEvaluate.data.details.length) {
          winnerFunErrors = winnerFunEvaluate.data.details.map((err: any) => {
            return {
              message: t(err),
            };
          });
        }
      }
      free_money_used = winnerFunEvaluate.data.main_amount;
    }
  } else if (window.config.useBonusEvaluation === '1' && hasBets) {
    const walletBonusesHash: any = {};
    if (walletBonuses) {
      walletBonuses.forEach((wb: any) => {
        walletBonusesHash[wb.id] = wb.name;
      });
    }

    const bonuses: any[] = [];
    let usedBonuses: any[] = [];
    let usedRingFences: any[] = [];
    let errorBonuses: any[] = [];
    //let eligible = false;

    if (
      walletBonusEvaluate &&
      walletBonusEvaluate.success &&
      walletBonusEvaluate.data &&
      walletBonusEvaluate.data.bonus
    ) {
      bLimit = free_money_used = twoDecimalsFloat(walletBonusEvaluate.data.free_money_used);
      usedBonuses = walletBonusEvaluate.data.bonus ? walletBonusEvaluate.data.bonus : [];
      usedRingFences = walletBonusEvaluate.data.ring_fence ? walletBonusEvaluate.data.ring_fence : [];
      errorBonuses = walletBonusEvaluate.data.details ? walletBonusEvaluate.data.details : [];
      //eligible = walletBonusEvaluate.data.eligible;
      valid = walletBonusEvaluate.data.valid;
    } else {
      valid = bLimit >= amount;
    }

    walletBonuses.forEach((wb: any) => {
      let total_used = 0;
      let balance_total = wb.amount;

      // collect what would the total amount ring fence + bonus would be
      const rf = walletRingFences.find((rf: any) => wb.id === rf.id);
      if (rf) {
        balance_total += rf.amount;
      }

      // used ring fence
      const urf = usedRingFences.find((rf) => wb.id === rf.id);
      if (urf) {
        bLimit += twoDecimalsFloat(urf.balance_used);
        total_used += twoDecimalsFloat(urf.balance_used);
      }

      // used bonus
      const ub = usedBonuses.find((b) => wb.id === b.id);
      if (ub) {
        bLimit += twoDecimalsFloat(ub.balance_used);
        total_used += twoDecimalsFloat(ub.balance_used);
      }

      total_used = twoDecimalsFloat(total_used);
      bonuses.push({ id: wb.id, name: wb.name, balance: balance_total, balance_used: total_used ? -total_used : 0 });
    });

    bLimit = twoDecimalsFloat(bLimit);
    //bonuses.sort((a, b) => a.balance - b.balance);

    //console.log("bonuses", bonuses, errorBonuses);
    bonusEvaluateMessagesEligible = bonuses.map((b, i) => {
      const eb: any = errorBonuses.filter((eb) => eb.id === b.id);
      hasBonusEvaluateMessages = true;

      const errors = eb
        .filter((e: any) => e.error && e.error.type === 'ticket')
        .map((e: any) => {
          const args = [...e.error.args];
          args[0] = (walletBonusesHash[eb.id] ? walletBonusesHash[eb.id] + ': ' : '') + t(args[0]); // see if we have a translation for that message

          const message = formatMessage(...args);
          if (e.error.section === 'stake') {
            stakeErrorBonuses.push(message);
            return null;
          } else if (e.error.section !== 'generic') {
            return null;
          }

          return {
            message,
          };
        })
        .filter((e: any) => e !== null);

      return {
        name: b.name,
        hasErrors: !valid && eb.length > 0,
        hasWarnings: valid && eb.length > 0,
        // @ts-ignore
        balance: formatAmountWithDecimals(b.balance, 2, true),
        // @ts-ignore
        balance_used: formatAmountWithDecimals(b.balance_used, 2, true),
        currency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
        errors: errors,
      };
    });
  }

  const handleRuleOpen = (type: any, eventName: any) => () => {
    //debug(`handleRuleOpen: type = ${type}, eventName = ${eventName}`);
    setRuleDialog({
      open: true,
      eventName: eventName,
      type: type,
    });
  };
  const handleRuleClose = () => {
    setRuleDialog({
      open: false,
      eventName: '',
      type: '',
    });
  };

  const generateWinnerFunChooseStakeText = () => {
    if (
      !winnerFunEvaluate ||
      !winnerFunEvaluate.data ||
      !winnerFunEvaluate.data.eligible ||
      !winnerFunEvaluate.data.eventPointsMultiplier ||
      winnerFunEvaluate.data.eventPointsMultiplier === 1
    ) {
      return {
        hasEvaluation: false,
      };
      //return <div className={classes.stakeTitle}>{t('Choose Stake')}</div>;
    }

    return {
      hasEvaluation: true,
      points: twoDecimalsFloat(winnerFunEvaluate.data.maxWinAmount - winnerFunEvaluate.data.originalMaxWinAmount),
      eligible: winnerFunEvaluate.data.eligible,
      eventName: winnerFunEvaluate.data.event_name.text,
    };
  };

  let onlineTicketDisabled = false;
  if (selectedFreeBet === -1 && !(bLimit !== 0 && bLimit >= amount)) {
    onlineTicketDisabled = true;
  } else if (!placeTicketEnabled) {
    onlineTicketDisabled = true;
  } else if (amount < betLimits.minAmount || amount > betLimits.maxAmount || stakeAmount > betLimits.maxAmount) {
    onlineTicketDisabled = true;
  } else if (isWinnerFun && !valid) {
    onlineTicketDisabled = true;
  } else {
    let hasBetBuilderBets = false;

    liveBets.forEach((b: any) => {
      if (b.betType === 'betBuilder') {
        hasBetBuilderBets = true;
      }
    });
    prematchBets.forEach((b: any) => {
      if (b.betType === 'betBuilder') {
        hasBetBuilderBets = true;
      }
    });

    if (hasBetBuilderBets && ticketType === 'system') {
      onlineTicketDisabled = true;
    }
  }

  let hasBoostAvailable = false;
  let hasBoostEligible = false;

  if (isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.boostAvailable) {
    hasBoostAvailable = true;
    hasBoostEligible = true;
  }

  if (isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.duplicateEvent !== null) {
    hasBoostEligible = false;
    if (winnerFunBoostActive) {
      setTimeout(() => toggleWinnerFunBoost(false), 0);
    }
  }

  const genWinnerFunWalletDetails = () => {
    if (!winnerFunEvaluate || !winnerFunEvaluate.data)
      return {
        hasEvaluation: false,
      };

    return {
      hasEvaluation: true,
      // @ts-ignore
      walletMain: formatAmountWithDecimals(wallet.main, 2, true),
      currency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
      // @ts-ignore
      walletMainDeduct: formatAmountWithDecimals(winnerFunEvaluate.data.main_amount, 2, true),
      eligible: winnerFunEvaluate.data.eligible,
      eventName: winnerFunEvaluate.data.event_name.text,
      // @ts-ignore
      walletSecondary: formatAmountWithDecimals(wallet.secondary, 2, true),
      walletSecondaryDeduct: winnerFunEvaluate.data.secondary_amount,
      seasonIcon: winnerFunEvaluate.data.season_icon,
      eventIcon: winnerFunEvaluate.data.event_icon,
      evalError: winnerFunEvaluate.data.evalError ?? 0, // -1: not eleigible with some events or -6: duplicate
      duplicateEvent: winnerFunEvaluate.data.duplicateEvent,
    };
  };

  let disableBetTicketInShop = false;
  if (typeof window.config.disableBetTicketInShop !== 'undefined' && window.config.disableBetTicketInShop === '1') {
    disableBetTicketInShop = true;
    if (!ticketOnline) {
      setTimeout(() => {
        betsSlipSetTicketOnline(true);
      }, 0);
    }
  }

  React.useEffect(() => {
    let setTicket = false;

    if (!isWinnerFun && prematchBets.length) {
      const exists: any = {};

      prematchBets.forEach((bet: any) => {
        exists[`${bet.idMatch}-${bet.idMbo}`] = true;
      });

      dayMultiBets.find((multiBet: any) => {
        if (multiBet.stakes.length === prematchBets.length) {
          let isComplete = true;

          multiBet.stakes.forEach((ticket: any) => {
            const key = `${ticket.event_id}-${ticket.stake_id}`;
            if (!exists[key]) isComplete = false;
          });

          if (isComplete) {
            props.betsSlipSetMultiBetDayNumber(multiBet.number);
            setTicket = true;
            return true;
          }
        }

        return false;
      });
    }

    if (!setTicket) {
      props.betsSlipSetMultiBetDayNumber(0);
    }
  }, [isWinnerFun, dayMultiBets, prematchBets, props.betsSlipSetMultiBetDayNumber]);

  let overrideTotalOdds = null;
  let overrideWinning = null;
  let whtAmount = totalMaxWinTax;
  let totalWinnings = totalMaxWinAmount;
  let totalReturn = totalTaxedMaxWinAmount;

  if (
    !isWinnerFun &&
    dayMultiBetNumber > 0 &&
    dayMultiBets &&
    dayMultiBets.length &&
    currentTicket.ticketType === 'single'
  ) {
    const dayMultiBet = dayMultiBets.find((dmb: any) => dmb.number === dayMultiBetNumber);

    if (dayMultiBet && dayMultiBet.express_factor) {
      overrideTotalOdds = totalOdds * dayMultiBet.express_factor;
      overrideWinning = totalTaxedMaxWinAmount * dayMultiBet.express_factor;

      whtAmount = totalMaxWinTax * dayMultiBet.express_factor;
      totalWinnings = totalMaxWinAmount * dayMultiBet.express_factor;
      totalReturn = totalTaxedMaxWinAmount * dayMultiBet.express_factor;
    }
  }

  const contextValue = {
    overrideTotalOdds,
    overrideTotalOddsStr: formatTotalOddsValue(overrideTotalOdds),
    overrideWinning,
    overrideWinningStr: formatAmount2(overrideWinning),

    hasBets,
    valid,
    loggedIn,

    walletMain: wallet.main,
    // @ts-ignore
    walletMainStr: formatAmountWithDecimals(wallet.main, 2, true),
    walletCurrency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
    freeMoneyUsed: free_money_used,
    freeMoneyUsedAmount: selectedFreeBet !== -1 ? 0 : free_money_used,
    // @ts-ignore
    freeMoneyUsedAmountStr: formatAmountWithDecimals(selectedFreeBet !== -1 ? 0 : free_money_used, 2, true),

    ticketAppliedBonus,
    ticketBonusEligibles,
    bonusEvaluateMessagesEligible,
    placeTicketErrorMessage,

    hasBoostAvailable,
    hasBoostEligible,

    isWinnerFun,
    winnerFunTotalOdds: winnerFunEvaluate ? winnerFunEvaluate.data.wfTotalOdds : 0,
    winnerFunTotalOddsStr: winnerFunEvaluate ? formatTotalOddsValue(winnerFunEvaluate.data.wfTotalOdds) : 0,
    winnerFunEvaluate,
    winnerFunDetails: genWinnerFunWalletDetails(),
    winnerFunErrors,
    winnerFunChooseStakeText: generateWinnerFunChooseStakeText(),
    winnerFunBoostActive,

    totalOdds: totalOdds,
    totalOddsStr: formatTotalOddsValue(totalOdds),
    amount,
    winning:
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data
        ? winnerFunEvaluate.data.maxWinAmount
        : totalTaxedMaxWinAmount,
    winningStr: formatAmount2(
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data
        ? winnerFunEvaluate.data.maxWinAmount
        : totalTaxedMaxWinAmount,
    ),

    minWinning:
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.minWinAmount : minWinAmount,
    minWinningStr: formatAmount2(
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.minWinAmount : minWinAmount,
    ),

    infoPanel: {
      stake: stake,
      exciseDuty: tax,
      totalStake: amount,
      totalOdds,
      whtAmount,
      totalWinnings,
      totalReturn,
    },

    totalWinnings: totalMaxWinAmount,
    totalReturn: totalTaxedMaxWinAmount,
    whtAmount: totalMaxWinTax,

    ticketType,
    tax,
    taxStr: formatAmount2(tax),
    taxPercent,
    stakeAmount,

    stakeErrorBonuses,
    handleStakeChange,
    onClickStakeDec: (e: any) => onStakeDec(-1, e),
    onClickStakeInc: (e: any) => onStakeInc(-1, e),
    onStakeDec,
    onStakeInc,

    maxBet: maxBet.enabled,
    toggleMaxBet,

    selectedFreeBet,
    selectedFreeBetName: selectedFreeBet !== -1 ? freeBets[selectedFreeBet]?.name ?? '' : '',
    freeBets: buildFreeBets(),

    ticketOnline,
    onlineTicketDisabled,
    placeTicketEnabled,
    createTicket,
    handleLogin,

    autoAcceptOddChange,
    handleAutoAcceptChange,

    allowTicketPlaceWithInvalidEvents,
    handleAllowTicketPlaceWithInvalidEvents,

    disableBetTicketInShop,
    openPlaceBet,
    handlePlaceBetClose,
    currentTicket: JSON.parse(JSON.stringify(currentTicket)),

    ruleDialogOpen: ruleDialog.open,
    handleRuleClose,
    ruleDialogEventName: ruleDialog.eventName,
    ruleDialogType: ruleDialog.type,

    openDeleteDialog,
    toggleDeleteDialog,
    handleDeleteButton: () => {
      // TODO: not defined. we should show a popup asking if the user wants to delete the ticket
      clearBets();
      toggleDeleteDialog();
    },

    betsInc1: parseFloat((window.config.betsInc1 ?? 5).toString()),
    betsInc2: parseFloat((window.config.betsInc2 ?? 10).toString()),
    betsInc3: parseFloat((window.config.betsInc3 ?? 20).toString()),
    betsInc4: parseFloat((window.config.betsInc4 ?? 50).toString()),
  };

  //console.log('SoldCard[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{props.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

const mapStateToProps = (state: RootState, props: any) => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    isWinnerFun: bst.app.isWinnerFun,
    wallet: bst.app.isWinnerFun ? state.winnerFun.wallet : state.wallet,
    profile: state.profile,

    stake: ct.stake,
    amount: ct.amount,
    tax: ct.tax,
    taxPercent: ct.taxPercent,
    winAmount: ct.totalMaxWinAmount,
    minWinAmount: ct.totalMinWinAmount,

    totalMinWinAmount: ct.totalMinWinAmount,
    totalTaxedMinWinAmount: ct.totalTaxedMinWinAmount,
    totalMinWinTax: ct.totalMinWinTax,

    totalMaxWinAmount: ct.totalMaxWinAmount,
    totalTaxedMaxWinAmount: ct.totalTaxedMaxWinAmount,
    totalMaxWinTax: ct.totalMaxWinTax,

    totalOdds: ct.totalOdds,
    autoAcceptOddChange: ct.autoAcceptOddChange,
    allowTicketPlaceWithInvalidEvents: ct.allowTicketPlaceWithInvalidEvents,
    placeTicketEnabled: ct.placeTicketEnabled,
    placeTicketErrorMessage: ct.placeTicketErrorMessage,
    errorMessage: ct.errorMessage,
    auth: state.authentication,
    ticketOnline: ct.ticketOnline,
    ticketType: ct.ticketType,
    liveBets: ct.live.selected,
    prematchBets: ct.prematch.selected,
    bonus: ct.bonus,
    walletBonusEvaluate: ct.bonusEvaluate,
    walletBonuses: getBetsBonuses(state),
    walletRingFences: getBetsRingFences(state),
    currentTicket: ct,
    winnerFunEvaluate: ct.winnerFunEvaluate,
    winnerFunBoostNum: bst.app.isWinnerFun ? getWinnerFunBoostNum(state) : 0,
    winnerFunBoostActive: ct.useWinnerFunBoost,

    freeBets: state.freeBets.freeBets,
    selectedFreeBet: bst.betsSlip.selectedFreeBet,
    selectedFreeBetSubIndex: bst.betsSlip.selectedFreeBetSubIndex,
    freeBetsEvaluate: ct.freeBetsEvaluate,
    dayMultiBetNumber: ct.dayMultiBetNumber,
    dayMultiBets: bst.config.dayMultiBets,
  };
};

const actionCreators = {
  clearBets: betsSlipClear,
  clearCreateStatus: betsSlipClearCreateStatus,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  toggleAutoAcceptOddChange: betsSlipToggleAutoAcceptOddChange,
  toggleAllowTicketPlaceWithInvalidEvents: betsSlipToggleAllowTicketPlaceWithInvalidEvents,
  liveCreateTicket: liveCreateTicketRequest,
  prematchCreateTicket: prematchCreateTicketRequest,
  betsSlipSetTicketOnline: betsSlipSetTicketOnline,
  doLogin: doLogin,
  requestBonusEvaluation: betsBonusRequestEvaluation,
  toggleWinnerFunBoost: betsSlipToggleWinnerFunBoost,
  ticketWinnerFunOpenedListRequest: ticketWinnerFunOpenedListRequest,
  betSlipSetFreeBetIndex: betSlipSetFreeBetIndex,
  betSlipFreeBetClear: betSlipFreeBetClear,
  betsSlipSetMultiBetDayNumber: betsSlipSetMultiBetDayNumber,
};

// @ts-ignore
export default withTranslation()(connect(mapStateToProps, actionCreators)(SoldCard));
